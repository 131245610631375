import React from 'react'
// import PropTypes from 'prop-types'
import s from './style.module.scss'

const C = ({ children }) => <span className={s.container}>{children}</span>

C.propTypes = {}

C.defaultProps = {}

export default C

import React from 'react'

import BlogPage from '../components/layouts/blog_page'
import Em from '../components/common/emphasis'
import Bold from '../components/common/bold'
import P from '../components/common/paragraph'
import TextLink from '../components/common/text_link'
import Flag from '../components/page_one_shots/flagify/flag'
import { Mobile, Desktop } from '../components/responsive'
// import Image from '../components/common/image'

import META_IMAGE_SRC from '../images/lips.jpg'
// const ME_SRC = META_IMAGE_SRC

const NON_BINARY_STRIPES = [
  {
    color: '#fef35c',
    weight: 1,
  },
  {
    color: 'white',
    weight: 1,
  },
  {
    color: '#9460cc',
    weight: 1,
  },
  {
    color: 'black',
    weight: 1,
  },
]

export default ({ location }) => (
  <BlogPage
    location={location}
    metaImage={META_IMAGE_SRC}
    metaImageAlt="Juniper Preston wearing red lipstick"
  >
    <P>I have something that I'd like to write down here explicitly.</P>

    <P>
      If you follow me much, you'll have seen me experimenting recently with the
      way I dress, the way I present myself more generally and the way I conduct
      myself with others. Originally, I set out on that road because I felt
      that, as a cis man who felt a bit constrained by the choices allowed me by
      society, I had a duty to push some boundaries.
    </P>

    <P>
      Somewhere along the way another question took root, separate from concerns
      about appearances, but I think pretty inevitable as a side effect of
      questioning so much I'd held to be the norm for so long. Is a cis man
      really what I feel like? I find myself feeling an urge to reject
      identification as a man. Specifically, to reject many of the expectations
      put on me by my assigned gender. I'll get straight to it: I feel like the
      answer to my question is <Em>no</Em>.
    </P>

    <P>
      <Bold>So, this is me coming out as non-binary.</Bold>
    </P>

    <P>
      I'm not know-it-in-your-bones, deep in my heart certain that this is right
      for me. That's OK though. I'm trying it on. I'm experimenting. Committing
      to this will, I think, help me to let go of some of my own internalised
      misconceptions about what I should and shouldn't be. Throwing a{' '}
      <TextLink
        text="picture of myself in a stripey dress"
        href="https://twitter.com/unwttng/status/1107775341173587968"
      />{' '}
      into the Twitter feeds of most of my professional network showed me that
      apparently I'm all about these big statements that help me to position
      myself mentally and emotionally closer to where I want to be. This is just
      another one of those.
    </P>

    <Desktop>
      <Flag aria-hidden={true} wide flag={{ stripes: NON_BINARY_STRIPES }} />
    </Desktop>
    <Mobile>
      <Flag aria-hidden={true} flag={{ stripes: NON_BINARY_STRIPES }} />
    </Mobile>

    <P>
      What this means in practice is that I don't strongly identify with either
      of the two binary genders. Sometimes I feel more or less distant from
      wanting to call myself a man. I seem to have no idea when those times will
      come. This will sometimes translate into an outward appearance that's more
      or less close to traditionally masculine or feminine. But not always:
      gender isn't clothing. Expect a mix. Expect both at once, sometimes a
      deliberate lack of either.
    </P>

    <P>
      I'm not too concerned with people using different pronouns for me right
      now. Where there's a chance to specify them, I'll likely go for{' '}
      <Bold>they / them</Bold>. Or maybe a play like <Bold>they / him</Bold>{' '}
      that speaks to where I currently feel like I sit as an AMAB non-binary
      person who doesn't make any strong efforts at full androgeny. I don't
      know. This is new to me: please be patient.
    </P>

    <P>
      It wouldn't be a stretch to say that part of this is about distancing
      myself from the worst parts of masculinity. I'm certainly not aiming to
      escape or absolve myself of the role I've occupied and the privileges I've
      advantaged from as a man in this patriarchy, and which I will often
      continue to as a male-presenting person. Quite the opposite: this partly
      represents a fresh commitment to challenging the toxicity and privilege
      that's woven through every part of men's place in society.
    </P>

    <P>
      I won't be asking you, if you know me, to change your behaviour in any
      specific way. Just to continue to be supportive of my choices and to let
      me do my thing. I'll do the same for you. If you have any questions at
      all, or feel unclear about why I might be identifying this way, I'd love
      to talk to you. Catch me for a chat in whatever way is relevant.
    </P>

    <P>
      To my loved ones and to kind Internet strangers alike: thank you for
      helping me to this point. I'm happier in myself than I can remember being.
      It's a huge privilege even to have been able to bring myself to this point
      with some sense of confidence and safety. Your support has enabled that.
    </P>

    <P>
      It'll be a journey, still. I don't expect it to be easy, but I'm hopeful.
      Scared too. But mostly hopeful.
    </P>
  </BlogPage>
)
